<script setup>
const skeletonComponents = [
  `
  <div class="line short"></div>
  <div class="line medium"></div>
  <div class="line long"></div>
  <div class="line medium"></div>
  `,
  `
  <div class="line medium"></div>
  <div class="line short"></div>
  <div class="line medium"></div>
  <div class="line long"></div>
  `,
  `
  <div class="line short"></div>
  <div class="line medium"></div>
  <div class="line long"></div>
  <div class="line short"></div>
  `,
  '', // empty div
];

const skeletonComponentSequence = '230131223101312101101'.split('');
const getSkeletonComponent = (index) => skeletonComponents[index];
</script>

<template>
<div class="superdoc-viewer-loader">
  <div class="superdoc-viewer-loader__loading">
    <div class="superdoc-viewer-loader__skeleton">
      <div 
        v-for="(i, index) in skeletonComponentSequence" 
        v-html="getSkeletonComponent(i)" 
        :key="index"
        :class="'superdoc-viewer-loader__line-ctn superdoc-viewer-loader__line-ctn--type-' + i">
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.superdoc-viewer-loader {
  /** */
}

.superdoc-viewer-loader__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.superdoc-viewer-loader__skeleton {
  padding-top: 48px;
}

.superdoc-viewer-loader__line-ctn {
  display: flex;
  margin: 10px 0;
}

.superdoc-viewer-loader__line-ctn--type-2 {
  padding-left: 30px;
}

.superdoc-viewer-loader__line-ctn :deep(.line) {
  background-color: grey;
  background: linear-gradient(to right, #d0d0d0, #fafafa, #eee);
  background-size: 400%;
  background-position-x: 100%;
  animation: shimmer 1s infinite linear;
  border-radius: 5%;
  height: 12px;
  margin: 0 5px;
}

.superdoc-viewer-loader__line-ctn :deep(.line.short) {
  width: 70px;
}

.superdoc-viewer-loader__line-ctn :deep(.line.medium) {
  width: 150px;
}

.superdoc-viewer-loader__line-ctn :deep(.line.long) {
  width: 300px;
}

@keyframes shimmer {
  to {
    background-position-x: 0%;
  }
}
</style>
