<script setup>
const props = defineProps({
  name: {
    type: String,
  },
  zoomValue: {
    type: String,
  },
  zoomValueBuffer: {
    type: String,
  },
  zoomOptionsVisible: {
    type: Boolean,
  },
  headerCtas: {
    type: Array,
  },
  overflowItems: {
    type: Array,
  },
  overflowOptionsVisible: {
    type: Boolean,
    default: false,
  },
  isMobile: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'zoom-input',
  'zoom-submit',
  'zoom-option-click',
  'zoom-dropdown-click',
  'button-click',
  'close'
]);

const zoomOptions = ['50%', '75%', '90%', '100%', '125%', '150%', '200%'];

const onZoomDropdownClick = () => {
  emit('zoom-dropdown-click');
};

const onZoomInput = (event) => {
  emit('zoom-input', { value: event.target.value });
};

const onZoomSubmit = () => {
  emit('zoom-submit');
};

const onZoomOptionClick = (option) => {
  emit('zoom-option-click', { option });
};

const onButtonClick = (button) => {
  emit('button-click', { button });
};

const getCtaIconClass = (cta) => {
  const classes = ['fa-' + cta.icon];
  if (cta.type === 'secondary') classes.push('fal');
  else classes.push('fas');
  return classes;
};
</script>

<template>
<div class="hrbr-superdoc-viewer-header">
  <div
    v-if="isMobile"
    class="hrbr-superdoc-viewer__close-button"
    @click="emit('close')"
  >
    <i class="fas fa-times"></i>
  </div>

  <div v-if="isMobile" class="hrbr-superdoc-viewer-header__title hrbr-superdoc-viewer-header__title--mobile">{{ name }}</div>

  <div class="hrbr-superdoc-viewer-header__parts">
    <div class="hrbr-superdoc-viewer-header__part hrbr-superdoc-viewer-header__part--left">
      <div
        v-if="!isMobile"
        class="hrbr-superdoc-viewer-header__title hrbr-superdoc-viewer-header__title--desktop">
        {{ name }}
      </div>

      <div v-if="!isMobile" class="hrbr-superdoc-viewer-header__separator"></div>

      <div class="hrbr-superdoc-viewer-dropdown" @click="onZoomDropdownClick">
        <input
          class="hrbr-superdoc-viewer-dropdown__zoom-input"
          :value="zoomValueBuffer"
          :placeholder="zoomValue"
          @input="onZoomInput"
          @keydown.enter="onZoomSubmit"
        />
        <i :class="'fas fa-caret-' + (zoomOptionsVisible ? 'up' : 'down')"></i>
        <div class="hrbr-superdoc-viewer-dropdown__options" v-if="zoomOptionsVisible">
          <div
            class="hrbr-superdoc-viewer-dropdown__option"
            v-for="(option, index) in zoomOptions"
            :key="index"
            @click="onZoomOptionClick(option)">
            <span>{{ option }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="hrbr-superdoc-viewer-header__part hrbr-superdoc-viewer-header__part--right">
      <div
        v-for="cta in headerCtas"
        class="hrbr-superdoc-viewer-header__cta"
        :class="[`hrbr-superdoc-viewer-header__cta--${cta.type}`, cta.disabled ? 'disabled' : null]"
        :title="cta.title || cta.label"
        :key="cta.id"
        @click="onButtonClick(cta)">
        <i v-if="cta.icon" class="hrbr-superdoc-viewer-header__cta-icon" :class="getCtaIconClass(cta)"></i>
        <span>{{ cta.label }}</span>
      </div>

      <div
        v-if="overflowItems && overflowItems.length"
        class="hrbr-superdoc-viewer-header__overflow"
        @click="overflowOptionsVisible = !overflowOptionsVisible">
        <div class="hrbr-superdoc-viewer-dropdown">
          <i class="fas fa-ellipsis-vertical"></i>
          <div
            v-if="overflowOptionsVisible"
            class="hrbr-superdoc-viewer-dropdown__options"
            :style="{ right: 0 }">
            <div
              class="hrbr-superdoc-viewer-dropdown__option"
              v-for="option in overflowItems"
              :key="option.id"
              @click="onButtonClick(option)">
              <i v-if="option.icon" :class="['hrbr-superdoc-viewer-header__cta-icon', 'fas', 'fa-' + option.icon]"></i>
              <span>{{ option.label }}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<style scoped>
.hrbr-superdoc-viewer-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 16px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 10px 0 0;
}

.hrbr-superdoc-viewer-header__title {
  position: relative;
  display: inline;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 500px;
}

.hrbr-superdoc-viewer__close-button {
  position: absolute;
  top: 7px;
  right: 16px;
  font-size: 18px;
  color: #333;
}

.hrbr-superdoc-viewer-header__title--desktop {
  font-size: 16px;
  font-weight: 400;
}

.hrbr-superdoc-viewer-header__parts {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hrbr-superdoc-viewer-header__part {
  display: flex;
  align-items: center;
}

.hrbr-superdoc-viewer-header__part--right {
  gap: 16px;
}

.hrbr-superdoc-viewer-header__separator {
  width: 1px;
  height: 20px;
  background-color: #e0e0e0;
  margin: 0 10px;
}

.hrbr-superdoc-viewer-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 5;
}

.hrbr-superdoc-viewer-dropdown__zoom-input {
  width: 50px;
  border: solid 1px #c4c4c4;
  border-radius: 8%;
  margin-right: 5px;
  text-align: center;
}

.hrbr-superdoc-viewer-dropdown__zoom-input:focus {
  outline: none;
}

.hrbr-superdoc-viewer-dropdown__options {
  position: absolute;
  top: 20px;
  z-index: 3;
  background-color: white;
  min-width: 50px;
  box-shadow: 1px 0px 15px 1px #00000014;
  border-radius: 8px;
  font-size: 13px;
  overflow: hidden;
}

.hrbr-superdoc-viewer-dropdown__option {
  cursor: pointer;
  padding: 5px;
  display: flex;
}

.hrbr-superdoc-viewer-dropdown__option:hover {
  background-color: #f7f7f7;
}

.hrbr-superdoc-viewer-dropdown__option:hover .span::selection {
  background-color: initial;
}

.hrbr-superdoc-viewer-header__cta {
  font-size: 16px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  padding: 7px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}

.hrbr-superdoc-viewer-header__cta.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.hrbr-superdoc-viewer-header__cta.disabled:hover {
  background-color: inherit;
}

.hrbr-superdoc-viewer-header__cta-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  width: 24px;
  height: 24px;
  position: relative;
  left: -2px;
}

.hrbr-superdoc-viewer-header__cta--primary {
  color: white;
  background-color: #1355ff;
}

.hrbr-superdoc-viewer-header__cta--primary:hover {
  background-color: #0133b7;
}

.hrbr-superdoc-viewer-header__cta--primary .hrbr-superdoc-viewer-header__cta-icon {
  color: white;
}

.hrbr-superdoc-viewer-header__cta--secondary {
  color: #333333;
  background-color: white;
  border: solid 1px #dbdbdb;
}

.hrbr-superdoc-viewer-header__cta--secondary:hover {
  background-color: #dbdbdb;
}

.hrbr-superdoc-viewer-header__cta--secondary .hrbr-superdoc-viewer-header__cta-icon {
  font-weight: 500;
  color: #333333;
}

.hrbr-superdoc-viewer-header__cta--tertiary {
  color: #1355ff;
  background-color: #e2e9fb;
}

.hrbr-superdoc-viewer-header__cta--tertiary:hover {
  background-color: #bccfff;
}

.hrbr-superdoc-viewer-header__cta--tertiary .hrbr-superdoc-viewer-header__cta-icon {
  color: #1355ff;
}

.hrbr-superdoc-viewer-header__cta--confirm {
  color: white;
  background-color: #00853d;
}

.hrbr-superdoc-viewer-header__cta--confirm:hover {
  background-color: #005d2b;
}

.hrbr-superdoc-viewer-header__cta--confirm .hrbr-superdoc-viewer-header__cta-icon {
  color: white;
}

.hrbr-superdoc-viewer-header__cta--alert {
  color: white;
  background-color: #cb0e47;
}

.hrbr-superdoc-viewer-header__cta--alert:hover {
  background-color: #8e0a32;
}

.hrbr-superdoc-viewer-header__cta--alert .hrbr-superdoc-viewer-header__cta-icon {
  color: white;
}

.hrbr-superdoc-viewer-header__overflow {
  cursor: pointer;
}

@media (max-width: 768px) {
  .hrbr-superdoc-viewer-header__title {
    max-width: calc(100vw - 45px);
  }

  .hrbr-superdoc-viewer-header__cta {
    padding: 0;
    background-color: #fff;
    border: none;
  }

  .hrbr-superdoc-viewer-header__cta:hover {
    background-color: transparent;
    opacity: .65;
  }

  .hrbr-superdoc-viewer-header__cta .hrbr-superdoc-viewer-header__cta-icon {
    font-size: 18px;
    color: #333;
  }

  .hrbr-superdoc-viewer-header__cta span {
    display: none;
  }
}
</style>
