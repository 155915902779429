<script setup>
import '@harbour-enterprises/superdoc/style.css'; /* move to entry point? */
import { ref, shallowRef, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { SuperDoc } from '@harbour-enterprises/superdoc';
import SuperdocViewerHeader from './SuperdocViewerHeader.vue';
import SuperdocViewerLoader from './SuperdocViewerLoader.vue';
import { DOCX_MIMETYPE } from './helpers/constants.js';
import throttle from '../../../utils/throttle.js';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  source: {
    type: [File, String],
    required: false,
  },
  editorStateJson: {
    type: Object,
    required: false,
  },
  fields: {
    type: Array,
    required: false,
    default: () => [],
  },
  annotations: {
    type: Array,
    required: false,
    default: () => [],
  },
  conversations: {
    type: Array,
    required: false,
    default: () => [],
  },
  modules: {
    type: Object,
    default: () => {},
  },
  attachments: {
    type: Array,
    required: false,
    default: () => [],
  },
  headerCtas: {
    type: Array,
    default: () => [],
    validator: (ctas) => {
      const requiredKeys = ['id', 'label', 'type'];
      const types = ['primary', 'secondary', 'tertiary', 'confirm', 'alert'];
      if (!ctas.length) return false;
      const valid = ctas.every((cta) => {
        return requiredKeys.every((key) => cta[key]) && types.includes(cta.type);
      });
      if (!valid) {
        console.error(`Header CTAs must have {${requiredKeys.join(', ')}} keys and a "type" of {${types.join(', ')}}`);
        return false;
      }
      return true;
    },
  },
  overflowItems: {
    type: Array,
    required: false,
  },
  user: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits([
  'ready',
  'load',
  'error',
  'close',
  'editorCreate',
  'editorBeforeCreate',
  'downloadCKdocument',
  'comments-update',
]);

const superdocInstance = shallowRef(null);
const supereditorInstance = shallowRef(null);

const documentData = ref(null);
const documentType = ref('');
const documentMode = 'viewing';

const isLoading = ref(true);
const isMobileView = ref(false);
const isZoomMode = ref(false);
const zoomOptionsVisible = ref(false);
const overflowOptionsVisible = ref(false);
const sideBarOpened = ref(false);

const zoomValue = ref('100%');
const zoomValueBuffer = ref('100%');
const pinchScale = ref(1);
const documentInitialWidth = ref(null);
const modalWidth = ref(960);

const containerElem = ref(null);
const containerInnerElem = ref(null);

// For future download via converter.
let documentDataString = '';

watch(isZoomMode, () => {
  getZoomedDocShift();
});

watch(containerElem, (val) => {
  if (val) {
    document.addEventListener('wheel', onPinchZoom, { passive: false });
  }
});

const initSuperdoc = async () => {
  try {
    documentData.value = await getDocumentData();
    documentType.value = documentData.value.type;
    isLoading.value = false;
    emit('load', documentData.value);
  } catch (e) {
    emit('error', e);
    isLoading.value = false;
    window.alert('Error opening document');
    return;
  }

  const annotations = getProcessedAnnotations();

  const config = {
    selector: `#superdoc-${props.id}`,
    user: props.user,
    pagination: true,
    annotations: true,
    documents: [
      {
        id: props.id,
        data: documentData.value,
        state: props.editorStateJson,
        fields: props.fields,
        attachments: props.attachments,
        annotations,
      },
    ],
    modules: props.modules,
    documentMode,
    onEditorCreate,
    onEditorBeforeCreate,
    onPdfDocumentReady,
    onSidebarToggle,
    onCommentsUpdate,
  };

  const superdoc = new SuperDoc(config);
  superdocInstance.value = superdoc;

  emit('ready', { superdoc });
};

const onCommentsUpdate = (...args) => {
  emit('comments-update', ...args);
};

const getProcessedAnnotations = () => {
  // for sign date, which uses a different itemid
  const annotations = props.annotations?.map((annotation) => {
    let mappedAnnotation = { ...annotation };
    let matchingField = props.fields.find((field) => field.itemid === annotation.itemid);

    if (!matchingField) {
      matchingField = props.fields.find((field) => field.itemid === annotation.itemoriginalid);
      mappedAnnotation.itemid = matchingField ? annotation.itemoriginalid : annotation.itemid;
    }
    return mappedAnnotation;
  });

  return annotations;
};

const getDocumentData = async () => {
  // Check for file object, base64 string, or html string.
  if (props.source instanceof File) {
    return props.source;
  }

  if (typeof props.source === 'string') {
    documentDataString = props.source;

    if (props.source.startsWith('data:')) {
      const fileType = props.source.split(';')[0].split(':')[1];
      const fileExtension = fileType.split('/')[1];

      if (!['pdf', 'docx'].includes(fileExtension)) {
        throw new Error('Invalid file type');
      }

      const fileName = `${props.name}.${fileExtension}`;
      const fileResp = await fetch(props.source);
      const fileBlob = await fileResp.blob();
      return new File([fileBlob], fileName, { type: fileType });
    }

    if (props.source.startsWith('<')) {
      return new File([props.source], `${props.name}.html`, { type: 'text/html' });
    }
  }

  console.error('Invalid source type');

  throw new Error('Invalid source type');
};

const downloadDocument = async () => {
  if (!documentData.value) {
    return;
  }

  const fileType = documentData.value.type;

  if (fileType === DOCX_MIMETYPE) {
    let name = props.name;
    if (!name.endsWith('.docx')) {
      name = `${name}.docx`;
    }

    const exported = await supereditorInstance.value?.exportDocx();
    const blob = new Blob([exported], { type: DOCX_MIMETYPE });
    downloadFile(blob, name);
  } else if (fileType === "application/pdf") {
    downloadFile(documentData.value, `${props.name}.pdf`);
  } else if (fileType === "text/html") {
    emit('downloadCKdocument', {
      name: props.name,
      data: documentDataString,
    });
  }
};

const downloadFile = (fileBlob, fileName) => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(fileBlob);
  a.download =  fileName;
  a.click();
  a.remove();
};

const onEditorCreate = ({ editor }) => {
  supereditorInstance.value = editor;
  // For the editor should we set `documentMode` separately?
  supereditorInstance.value.setDocumentMode(documentMode);

  emit('editorCreate', { editor });
};

const onEditorBeforeCreate = ({ editor }) => {
  emit('editorBeforeCreate', { editor });
};

const onPdfDocumentReady = () => {
  getZoomToFit({ onFirstLoad: true });
  nextTick(() => applyNewZoom(zoomValueBuffer.value));
};

const onButtonClick = ({ button }) => {
  if (button.disabled) return;
  const defaultHandlers = { download: downloadDocument };
  const handler = button.onClick || defaultHandlers[button.id] || null;
  if (handler) handler();
};

const onSidebarToggle = (val) => {
  if (val) {
    const hasPlace = window.matchMedia('(min-width: 1160px)').matches;
    if (hasPlace) {
      modalWidth.value = 1160;
    } else if (!isMobileView.value) {
      getZoomToFit({ onCommentsAdded: true });
      applyNewZoom(zoomValueBuffer.value);
    }
  }
  sideBarOpened.value = val;
};

 const onContainerClick = () => {
  zoomOptionsVisible.value = false;
  overflowOptionsVisible.value = false;
};

const getZoomedDocShift = () => {
  const transformSize = 50;
  const outerContentCtn = containerElem.value;
  const innerContentCtn = containerInnerElem.value;
  const superdocElement = document.querySelector(superdocInstance.value.config.selector);

  if (!outerContentCtn || !innerContentCtn || !superdocElement) {
    return;
  }

  const outerWidth = outerContentCtn.clientWidth;
  const innerWidth = superdocElement.clientWidth;

  if (outerWidth >= innerWidth) {
    resetCursorAndPos();
    return;
  }

  innerContentCtn.style.cursor = 'ew-resize';
  const leftShift = ((innerWidth - outerWidth) / 2) / outerWidth * 100;
  innerContentCtn.style.left = leftShift + transformSize + '%';
};

const applyNewZoom = (newZoom) => {
  if (newZoom.endsWith('%')) newZoom = newZoom.slice(0, -1);

  if (isNaN(newZoom) || newZoom <= 0) newZoom = 100;
  else if (newZoom < 20) newZoom = 20;
  else if (newZoom > 200) newZoom = 200;

  zoomOptionsVisible.value = false;
  const viewerInner = containerInnerElem.value;
  superdocInstance.value.toolbar.setZoom(newZoom);
  const innerWidth = '100%';


  if (!isMobileView.value) {
    if (newZoom > 100) {
      viewerInner.style.width = (816 * (newZoom / 100)) + 'px';
      isZoomMode.value = true;
    } else {
      viewerInner.style.width = isMobileView.value ? innerWidth : '8.5in';
      isZoomMode.value = false;
    }
  } else {
    viewerInner.style.width = '100%';
    isZoomMode.value = false;
  }

  zoomValueBuffer.value = newZoom + '%';
  zoomValue.value = zoomValueBuffer.value;
  pinchScale.value = newZoom / 100;

  getZoomedDocShift();
};

// For wide pdf documents and for pdf resize
const getZoomToFit = ({ onFirstLoad, onCommentsAdded } = {}) => {
  if (documentType.value !== 'application/pdf') {
    return;
  }

  const contentCtn = isMobileView.value ? containerElem.value : containerInnerElem.value;

  if (!contentCtn) {
    return;
  }

  const sideBarOffset = sideBarOpened.value ? 300 : 0;

  if (onFirstLoad || !documentInitialWidth.value) {
    const superdocElement = document.querySelector(superdocInstance.value.config.selector);
    documentInitialWidth.value = superdocElement.clientWidth - sideBarOffset;
  }

  if (onCommentsAdded) {
    if (documentInitialWidth.value / (contentCtn.clientWidth - sideBarOffset) > 1) {
      zoomValueBuffer.value = Math.floor((contentCtn.clientWidth - sideBarOffset) / documentInitialWidth.value * 100).toString();
    }
    return;
  }

  if (documentInitialWidth.value / contentCtn.clientWidth > 1) {
    zoomValueBuffer.value = Math.floor(contentCtn.clientWidth / documentInitialWidth.value * 100).toString();
  }
};

const resetCursorAndPos = () => {
  const contentCtn = containerInnerElem.value;
  if (contentCtn) {
    contentCtn.style.cursor = 'initial';
    contentCtn.style.left = '50%';
  }
};

const onPinchZoom = (e) => {
  if (e.ctrlKey) {
    e.preventDefault();
    const s = Math.exp(-e.deltaY / 100);
    pinchScale.value *= s;
    const valueToApply = Math.floor(pinchScale.value * 100).toString();
    applyNewZoom(valueToApply);
  }
};

const onZoomInput = ({ value }) => {
  zoomValueBuffer.value = value;
};

const onZoomSubmit = () => {
  resetCursorAndPos();
  applyNewZoom(zoomValueBuffer.value);
};

const onZoomOptionClick = ({ option }) => {
  setTimeout(() => {
    applyNewZoom(option)
  }, 0);
};

const onZoomDropdownClick = () => {
  zoomOptionsVisible.value = !zoomOptionsVisible.value;
  zoomValueBuffer.value = '';
};

const onWindowResize = () => {
  isMobileView.value = window.matchMedia('(max-width: 768px)').matches;
  getZoomToFit();
  applyNewZoom(zoomValueBuffer.value);
  getZoomedDocShift();
};
const onWindowResizeThrottled = throttle(onWindowResize, 300);

onMounted(async () => {
  await initSuperdoc();

  onWindowResize();
  window.addEventListener('resize', onWindowResizeThrottled);
});

onUnmounted(() => {
  document.removeEventListener('wheel', onPinchZoom);
  window.removeEventListener('resize', onWindowResizeThrottled);
});

</script>

<template>
  <div class="hrbr-superdoc-viewer">
    <SuperdocViewerHeader
      v-bind="{
        name,
        zoomValue,
        zoomValueBuffer,
        zoomOptionsVisible,
        headerCtas,
        overflowItems,
        overflowOptionsVisible,
        isMobile: isMobileView,
      }"
      @zoom-input="onZoomInput"
      @zoom-submit="onZoomSubmit"
      @zoom-option-click="onZoomOptionClick"
      @zoom-dropdown-click="onZoomDropdownClick"
      @button-click="onButtonClick"
      @close="emit('close')"
    />

    <div class="hrbr-superdoc-viewer__main">
      <div
        class="hrbr-superdoc-viewer__container"
        :class="{ 'hrbr-superdoc-viewer__container--zoom-mode': isZoomMode }"
        @click="onContainerClick"
        ref="containerElem">
        <div class="hrbr-superdoc-viewer__container-inner" ref="containerInnerElem">
          <SuperdocViewerLoader v-if="isLoading" />
          <div
            class="hrbr-superdoc-viewer__superdoc sd-theme sd-theme-viewer"
            :class="{'is-pdf': documentData?.type === 'application/pdf'}"
            :id="`superdoc-${id}`">
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!isMobileView"
      class="hrbr-superdoc-viewer__close-button"
      @click="emit('close')"
    >
      <i class="fas fa-times"></i>
    </div>
  </div>
</template>

<style scoped>
.hrbr-superdoc-viewer {
  --container-background: #f5f5f5;

  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 100%;
  min-width: 960px;
  position: relative;
}

.hrbr-superdoc-viewer__main {
  height: calc(100vh - 185px);
  overflow: auto;
}

.hrbr-superdoc-viewer__main::-webkit-scrollbar {
  display: none;
}

.hrbr-superdoc-viewer__container {
  position: relative;
  height: 100%;
  overflow: auto;
  border-radius: 0 0 10px 10px;
  background-color: var(--container-background);
}

.hrbr-superdoc-viewer__container::-webkit-scrollbar {
  display: none;
}

.hrbr-superdoc-viewer__container-inner {
  display: flex;
  justify-content: center;
  width: 8.5in;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  min-height: 90vh;
  margin: 16px auto;
  touch-action: none;
  word-break: break-word;
}

.hrbr-superdoc-viewer__close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  font-size: 19px;
  color: #fff;
  position: absolute;
  top: -30px;
  right: 10px;
  padding: 5px;
  cursor: pointer;
}

.hrbr-superdoc-viewer__superdoc :deep(.superdoc) {
  justify-content: center;
}

/** make scroll available on touchscreen devices (todo fix issue with comments button on tablet) **/
@media (hover: none) and (pointer: coarse) {
  .hrbr-superdoc-viewer__superdoc :deep(.superdoc) {
    overflow: auto;
  }
}

.hrbr-superdoc-viewer__superdoc :deep(.superdoc__layers) {
  position: relative;
  background-color: white;
  border-radius: 8px;
  box-shadow: 1px 0 10px 1px #00000014;
}

.hrbr-superdoc-viewer__superdoc :deep(.pagination-separator) {
  color: var(--container-background);
}

.hrbr-superdoc-viewer__superdoc :deep(.superdoc-html-viewer) {
  overflow-y: auto;
}

@media (max-width: 960px) {
  .hrbr-superdoc-viewer {
    min-width: 100%;
  }

  .hrbr-superdoc-viewer__container-inner {
    width: 100%;
    margin: 0;
  }

  .hrbr-superdoc-viewer__superdoc :deep(.superdoc__layers) {
    border-radius: 0;
    box-shadow: none;
  }

  .hrbr-superdoc-viewer__superdoc :deep(.super-editor__content),
  .hrbr-superdoc-viewer__superdoc :deep(.superdoc-html-viewer__content) {
    width: 100%;
    min-width: unset;
  }
}

@media (max-width: 768px) {
  .hrbr-superdoc-viewer__superdoc :deep(.super-editor__content),
  .hrbr-superdoc-viewer__superdoc :deep(.superdoc-html-viewer__content) {
    padding: 30px 20px;
  }

}
</style>

<style>
/** Global styles */
.hrbr-superdoc-viewer-modal .modal-content {
  max-height: 100vh;
}
</style>
